import React from 'react';

import IconLink from '@components/common/IconLink';
import { Typography } from '@material-tailwind/react';

interface ILink {
  url: string;
  icon: string;
}

type PropsType = {
  title: string;
  description: string;
  url?: string;
  icon: string;
  links?: ILink[];
};

const InfoCard: React.FC<PropsType> = ({ title, description, url, icon, links }) => {
  return (
    <div className="flex items-center gap-2 lg:gap-4">
      <div>
        <div className="w-[50px] h-[50px] lg:w-[80px] lg:h-[80px] overflow-hidden rounded-full">
          <img src={icon} alt={title} className="w-full h-full object-cover object-center" />
        </div>
      </div>
      <div>
        <div className="flex gap-3 justify-between flex-wrap sm:flex-nowrap items-center pr-4">
          {url && (
            <Typography className="font-semibold text-lg">
              {url && (
                <a href={url} target="_blank" rel="noreferrer" className="hover:text-amber-500">
                  {title}
                </a>
              )}
            </Typography>
          )}
          {!url && (
            <div className="flex gap-2 lg:gap-3 md:justify-between sm:w-full">
              <Typography className="font-semibold text-lg">{title}</Typography>
            </div>
          )}
          {!!links?.length && (
            <div className="flex gap-3 self-end sm:self-center sm:order-3">
              {links.map(({ icon, url }) => (
                <IconLink key={url} url={url} icon={icon} size="small" variant='square'/>
              ))}
            </div>
          )}
          {!url && (
            <div className="w-full sm:w-auto flex mb-1 md:mb-0 sm:order-2">
              <div className="text-sm bg-amber-500 rounded-2xl px-2 py-1 text-gray-800 font-semibold whitespace-nowrap">
                Coming soon
              </div>
            </div>
          )}
        </div>
        <Typography variant="small" className="font-medium mt-2">
          {description}
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(InfoCard);
