import React from 'react';

import { Typography } from '@material-tailwind/react';
import cn from 'classnames';

type PropsType = {
  title?: string;
  url: string;
  icon: string;
  size?: 'large' | 'medium' | 'normal' | 'small';
  variant?: 'square' | 'circle';
  bg?: 'white';
  iconClassName?: string;
  subIcon?: string;
  asRowOnMobile?: boolean;
};

const IconLink: React.FC<PropsType> = ({
  title,
  url,
  icon,
  size = 'large',
  variant = 'square',
  iconClassName,
  subIcon,
  asRowOnMobile,
}) => {
  return (
    <div>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={cn(
          'flex items-center  group',
          asRowOnMobile && 'md:flex-col gap-3 md:gap-0',
          !asRowOnMobile && 'flex-col',
        )}
      >
        <div className="relative">
          <div
            className={cn(
              'overflow-hidden',
              variant === 'circle' && 'rounded-full',
              variant === 'square' && 'rounded-md',
              size === 'large' && 'w-[70px] h-[70px] lg:w-[100px] lg:h-[100px]',
              size === 'medium' && 'w-[50px] h-[50px] lg:w-[60px] lg:h-[60px]',
              size === 'normal' && 'w-[40px] h-[40px]',
              size === 'small' && 'w-[30px] h-[30px]',
              iconClassName,
            )}
          >
            <img src={icon} alt={title} className="w-full h-full" />
          </div>
          {subIcon && (
            <img
              className={cn(
                'absolute right-0 bottom-0',
                size === 'large' && 'w-[30px] h-[30px]',
                size === 'medium' && 'w-[20px] h-[20px] lg:w-[25px] lg:h-[25px]',
                size === 'normal' && 'w-[20px] h-[20px] lg:w-[25px] lg:h-[25px]',
                size === 'small' && 'w-[20px] h-[20px]',
              )}
              src={subIcon}
              alt={title + 'sub-icon'}
            />
          )}
        </div>
        {title && (
          <Typography
            variant={
              size === 'medium' || size === 'normal' || size === 'small' ? 'small' : undefined
            }
            className={cn('font-medium mt-1 group-hover:text-amber-500 transition-colors', {
              'md:text-center': asRowOnMobile,
              'text-center': !asRowOnMobile,
            })}
          >
            {title}
          </Typography>
        )}
      </a>
    </div>
  );
};

export default React.memo(IconLink);
